
.no-resize{
  resize: none;
}

.code{
  background: #f1f1f1;
  border: solid 1px #dddddd;
  border-radius: 4px;
  padding: 6px;
}

.gray {
  color: #A4A4A4;
}

.orange {
  color: orange;
}

.blue {
  color: blue;
}

.red {
  color: red;
}

.green {
  color: green;
}

.active-order {
  background-color: blue;
  color: white;

  a {
    color: white !important;
  }
}

.partner-logo {
  display: block;

  img {
    max-height: size(40);
    width: auto;
  }
}

.partner-name {
  img {
    max-height: size(25);
    width: auto;
    margin-right: size(10);
  }
}

.pg-card-top {
  .pg-btn-success {
    border: 1px solid $custom-004;
    border-radius: size(5);
    text-align: center;
    padding: szie(5);
  }
}

.pg-card-bottom {
  .txt-right {
    text-align: right;
  }
}

.indicator-card {
  border-radius: size(5);
  text-align: center;
  padding: size(10);
}

.indicator-red {
  //background-color: $danger-color;
  //color: $white;
  border: size(1) solid $danger-color;

  .indicator-label {
    border-bottom: size(1) dotted $danger-color;
    padding-bottom: size(5);
    margin-bottom: size(5);
  }
}


.indicator-gray {
  background-color: $border-gray;
  border: size(1) solid $border-gray;

  .indicator-label {
    border-bottom: size(1) dotted $border-gray;
    padding-bottom: size(5);
    margin-bottom: size(5);
  }
}

.indicator {
  .target {
    border-right: 1px solid $border-gray;

    .val {
      font-weight: bolder;
      font-size: size(25);
    }
  }

  .actual {

    .val {
      font-size: size(25);
    }
  }
}

.mentions {
  textarea {
    padding: size(10);

    border: 1px solid $border-gray;
    width: 94%;
    padding: size(10);
    height: size(100) !important;
    position: relative !important;
  }

  .div {
    all: unset;
  }
}